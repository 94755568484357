<template>
  <div class="row">
    <div class="col">
      <div
        class="form-group row"
        :class="{ 'has-error': errors.has('categoryId') }"
      >
        <div class="col-12 col-md-4">
          <label> Kategoria </label>
          <v-select
            multiple
            name="categoryId"
            v-model="selectedCategoryId"
            v-validate="'required'"
            label="name"
            :filter-by="this.filterSelect"
            :options="categoryList"
            :reduce="category => category.id"
          >
            <template #option="categoryList">
              <div class="product-label">
                {{ categoryList.name }}
              </div>
              <br />
              <cite>Id: {{ categoryList.id }}</cite>
              <hr />
            </template>
          </v-select>
          <small v-show="errors.has('categoryId')" class="text-danger">
            {{ $t("field_required") }}
          </small>
          <div class="hr-line-dashed"></div>
          <div class="form-group row">
            <div class="col-sm-4">
              <button
                type="button"
                class="btn btn-outline btn-primary"
                @click="handleSubmit"
              >
                {{ $t("download_excel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductListExport",
  data() {
    return {
      selectedCategory: null,
      categoryList: [],
      selectedCategoryId: [],
      id: null,
      filename: `lista_produktowa_${this.$route.params.shop_lang}`
    };
  },
  computed: {
    urlCategoryList() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/categories`;
    },
    urlProductListExport() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/exports/product-list`;
    }
  },
  created() {
    this.getCategoryList();
    if (this.$route.query.categoryId) {
      let str = this.$route.query.categoryId;
      if (str.length > 1) {
        this.selectedCategoryId = str.split(",").map(el => Number(el));
      } else this.selectedCategoryId.push(Number(str));
    }
  },
  methods: {
    handleSubmit() {
      if (this.selectedCategoryId.length == 0) {
        this.$toastr.error("Nie wybrano kategorii");
      } else {
        this.$emit("loading", true);
        this.$http
          .customRequest({
            url: `${this.urlProductListExport}?categoryIds=${this.selectedCategoryId}`,
            method: "GET",
            data: this.selectedCategoryId,
            responseType: "blob"
          })
          .then(response => {
            this.$func.downloadExcelFile(response, this.filename);
            this.$toastr.success("Plik został wygenerowany");
            this.$emit("loading", false);
          })
          .catch(() => {
            this.$toastr.error("Coś poszło nie tak :(");
            this.$emit("loading", false);
          });
      }
    },
    getCategoryList() {
      this.$emit("loading", true);
      return this.$http.get(this.urlCategoryList).then(resp => {
        if (resp.status === 200) {
          this.categoryList = resp.data.data;
          this.$emit("loading", false);
          return true;
        }
        this.$emit("loading", false);
      });
    },
    filterSelect: (option, id, search) => {
      const temp = search.toLowerCase();
      if (!option.group) {
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.id.toString().indexOf(temp) > -1
        );
      }
      return (
        option.name.toLowerCase().indexOf(temp) > -1 ||
        option.group.toLowerCase().indexOf(temp) > -1
      );
    }
  }
};
</script>
