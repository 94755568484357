<template>
  <Ibox :title="$t('product-list')">
    <template slot="content" slot-scope="{ setLoading }">
      <div class="mb-3">
        <small>{{ $t("small_description_product_list") }}.</small>
      </div>
      <ProductListExport slot="content" @loading="setLoading" />
    </template>
  </Ibox>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import ProductListExport from "@/components/Products/List/ProductListExport.vue";

export default {
  name: "ProductExport",
  components: {
    Ibox,
    ProductListExport
  }
};
</script>
